<template>
  <div class="info-accordion">
    <div
      class="d-flex flex-column mb-3"
      v-for="([label, value], index) in rows"
      :key="`info-row-${index}`"
    >
      <span class="text-dark-darken font-weight-bold">{{
        $t(`orderEditModals.infoDetails.${label}`) | capitalize
      }}</span>
      <span class="text-dark-darken">{{ value || 'N/A' }}</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <span class="text-dark-darken font-weight-bold">{{
        $t('orderEditModals.infoDetails.created_at') | capitalize
      }}</span>
      <span class="text-dark-darken">{{ dateFormat(data.created_at) || 'N/A' }}</span>
    </div>

    <div class="d-flex flex-column mb-3">
      <span class="text-dark-darken font-weight-bold">{{
        $t('orderEditModals.infoDetails.delivered_data') | capitalize
      }}</span>
      <span class="text-dark-darken">{{ dateFormat(data.delivered_data.date) || 'N/A' }}</span>
    </div>

    <div v-if="date && receiver.name">
      <div class="d-flex flex-column mb-3">
        <span class="text-dark-darken font-weight-bold">{{
          $t('orderEditModals.infoDetails.receiver') | capitalize
        }}</span>
        <span class="text-dark-darken">{{ receiver.name }}</span>
      </div>
    </div>

    <div class="d-flex flex-column mb-3">
      <span class="text-dark-darken font-weight-bold">{{
        $t('orderEditModals.infoDetails.status') | capitalize
      }}</span>
      <span
        class="state-info text-center mt-2 rounded"
        :style="{ backgroundColor: status.color || 'var(--secondary)' }"
        >{{ status.label }}</span
      >
    </div>

    <span class="text-dark-darken mb-3 d-block font-weight-bold">
      {{ $t('orderEditModals.orderLocation') }}
    </span>

    <static-background-image :data="this.data" tracking />
  </div>
</template>

<script>
import { dateFormat, safeStorage } from '@/utils';
import StaticBackgroundImage from './StaticBackgroundImage.vue';

export default {
  name: 'info-accordion',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    StaticBackgroundImage,
  },
  data() {
    return {
      receiver: null,
      date: null,
      status: null,
    };
  },
  beforeMount() {
    this.receiver = this.data.delivered_data.receiver;
    this.date = this.data.delivered_data.date;

    this.status = this.getStateStyle();
  },
  mounted() {
    document
      .querySelector('.tracking-link')
      .setAttribute('data-after', this.$t('orderEditModals.clickToTrack'));
  },
  computed: {
    rows() {
      // const {invoice, item} = this.data;
      const { invoice, volumesQtd, company } = this.data;
      const { number } = invoice;

      const numberEntries = Object.entries({ number });
      const totalEntries = Object.entries({ volumesQtd });

      const data = [
        ['partner', company.display_name],
        ['identification', this.data.items[0].identification],
        ['shipping_number', this.data.shipping_number],
        ...numberEntries,
        ...totalEntries,
      ].filter(([key]) => key !== 'date' && key !== 'id');

      return data;
    },
  },
  methods: {
    dateFormat(date) {
      return dateFormat(date);
    },
    getStateStyle() {
      const { statuses } = safeStorage.getItem('config');

      const status = statuses[this.data.operation.type][this.data.state]
        ? statuses[this.data.operation.type][this.data.state]
        : statuses[this.data.operation.type].outros;

      return status;
    },
  },
};
</script>

<style lang="scss">
$button-size: 230px;

.info-accordion {
  span {
    font-size: 13px;

    &.state-info {
      padding: 8px 15px;
      color: #fff;
      font-weight: bold;
      max-width: 220px;
    }
  }

  .tracking-link {
    position: relative;

    &::after {
      content: attr(data-after);
      padding: 6px 10px;
      background: var(--primary);
      border-radius: 0.25rem;
      position: absolute;
      width: $button-size;
      bottom: 10px;
      left: calc(50% - 115px);
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
