<template>
  <div class="static-image-and-location rounded">
    <component
      :is="tracking ? 'a' : 'div'"
      class="w-100 d-block tracking-link mb-3 rounded"
      :class="{
        'd-flex justify-content-center align-items-center': receiver || delivery,
        tracking,
        'bg-dark': !url,
      }"
      rel="noopener noreferrer"
      target="blank"
      :title="
        textKey
          ? $t(`orderEditModals.infoDetails.awaiting${textKey}`)
          : $t('orderEditModals.tracking')
      "
      :href="tracking ? this.data.tracking : null"
      :style="location"
      :staticmap="url"
    >
      <span
        v-if="!tracking && (delivery || receiver) && !url"
        class="explaning-text text-white text-center h3"
        >{{ textKey ? $t(`orderEditModals.infoDetails.awaiting${textKey}`) : '' }}</span
      >
    </component>
  </div>
</template>

<script>
export default {
  name: 'static-image-and-location',
  props: {
    data: {
      type: Object,
      required: true,
    },
    receiver: {
      type: Boolean,
      default: () => false,
    },
    delivery: {
      type: Boolean,
      default: () => false,
    },
    tracking: {
      type: Boolean,
      default: () => false,
    },
    pickedup: {
      type: Boolean,
      default: () => false,
    },
    textKey: {
      type: String,
      default: () => '',
    },
    static: {
      type: String,
      default: () => '',
    },
  },
  mounted() {
    document
      .querySelector('.tracking-link')
      .setAttribute('data-after', this.$t('orderEditModals.clickToTrack'));
  },
  computed: {
    newUrl() {
      const { type } = this.data.operation;
      let imageUrl = null;

      if (this.tracking) {
        const trueType = type === 'delivery' && !this.pickedup ? 'destination' : 'source';
        const { latitude, longitude } = this.data[trueType].geolocation;

        imageUrl = this.getMapboxStatic(latitude, longitude);
      } else if (this.delivery) {
        const { delivered_data, pickedup_data, state } = this.data;

        if (
          !this.pickedup &&
          (state === 'entregue' || state === 'coletado') &&
          delivered_data.date
        ) {
          const { latitude, longitude } = delivered_data.geolocation;

          imageUrl = this.receiver
            ? `url("${delivered_data.receiver.signature}")`
            : this.getMapboxStatic(latitude, longitude);
        } else if (
          this.pickedup &&
          (state === 'entregue' || state === 'coletado') &&
          pickedup_data &&
          pickedup_data.date
        ) {
          const { latitude, longitude } = pickedup_data.geolocation;

          imageUrl = this.receiver
            ? `url("${pickedup_data.receiver.signature}")`
            : this.getMapboxStatic(latitude, longitude);
        }
      }

      return imageUrl;
    },
    url() {
      const { type } = this.data.operation;
      const { delivered_data, pickedup_data } = this.data;
      const operationData = delivered_data.date ? delivered_data : pickedup_data;

      if (this.tracking) {
        const trueType = type === 'pickup' ? 'source' : 'destination';

        const { latitude, longitude } = this.data[trueType].geolocation;

        return this.getMapboxStatic(latitude, longitude);
      }

      if (this.delivery) {
        // eslint-disable-next-line
        const { state } = this.data;

        if (state === 'entregue' || state === 'coletado') {
          const { latitude, longitude } = operationData.geolocation;

          return this.receiver
            ? `url("${operationData.receiver.signature}")`
            : this.getMapboxStatic(latitude, longitude);
        }

        if (!this.delivery && this.pickedup && (state === 'entregue' || state === 'coletado')) {
          const { latitude, longitude } = operationData.geolocation;

          return this.receiver
            ? `url("${operationData.receiver.signature}")`
            : this.getMapboxStatic(latitude, longitude);
        }
      }

      if (this.static) {
        return `url("${this.static}")`;
      }

      return '';
    },
    location() {
      if (this.data) {
        const style = {
          backgroundPosition: 'center center',
          backgroundSize: this.receiver ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
          height: '200px',
          backgroundColor: 'var(--light-gray)',
          '--showTracking': this.data.tracking ? 'inline' : 'none',
        };

        return style;
      }

      return '';
    },
  },
  methods: {
    getMapboxStatic(latitude, longitude) {
      if (latitude && longitude) {
        return `${
          process.env.VUE_APP_API_BASE_URL
        }/static-map?longLat=${longitude.trim()},${latitude.trim()}&zoom=15&size=360x200`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
$button-size: 230px;

.static-image-and-location {
  position: relative;
  z-index: 10000000000 !important;

  .tracking-link {
    position: relative;

    span.explaning-text {
      font-size: 14px;
      font-weight: bold;
    }

    &.tracking::after {
      content: attr(data-after);
      padding: 6px 10px;
      background: var(--primary);
      border-radius: 0.25rem;
      position: absolute;
      width: $button-size;
      bottom: 10px;
      left: calc(50% - 115px);
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      display: var(--showTracking);
    }
  }
}
</style>
